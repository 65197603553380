import { useSelector } from 'react-redux';
import moment from 'moment';
import useMapperForNewRows from './useMapperForNewRowsForDetails';
import useColumnsForDetails from './useColumnsForDetails';

const useRowsForExcelDownload = ({ rows }) => {
  const { initialDate, lastDate } = useSelector((state) => state.cashFlow.root);

  const orderedRows = useMapperForNewRows({ rows });

  const { columns } = useColumnsForDetails(orderedRows, initialDate, lastDate);

  const dates = columns
    .filter((column) => column.name !== 'name')
    .reduce((acc, column) => {
      acc[column.name] = moment(column.name, 'MMM. YYYY')
        .startOf('month')
        .format();

      return acc;
    }, {});

  const isDateKey = (key) => {
    return /\b(?:ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic)\.\s\d{4}\b/.test(
      key,
    );
  };

  const maxDate = orderedRows.reduce((latestDate, row) => {
    Object.keys(row)
      .filter(isDateKey)
      .forEach((key) => {
        const date = moment(key, 'MMM YYYY', true);
        if (
          date.isValid() &&
          (latestDate === null || date.isAfter(latestDate))
        ) {
          latestDate = date;
        }
      });
    return latestDate;
  }, null);

  return {
    dates,
    orderedRows,
    maxDate,
  };
};

export default useRowsForExcelDownload;

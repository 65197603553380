import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Typography, Box, Paper, Button } from '@material-ui/core';
import moment from 'moment';
import { Alert, AlertTitle } from '@material-ui/lab';
import useTotalCashFlowToFetchRows from '../Core/customHooks/useTotalCashFlowToFetchRows';
import { updateContractCashflow, updateSalesCashflow } from '../Core/actions';
import AbsoluteCircularProgress from './styles/AbsoluteCircularProgress';
import DownloadButton from '../../../components/UI/DownloadButton/DownloadButton';
import TypeSelector from './views/TypeSelectorForTotalCashFlow';
import Style from './styles/TotalCashFlowMainView.module.scss';
import BuilderCreditSetupModalView from '../../BuilderCreditSetupModal/UI/BuilderCreditSetupModalView';
import useGenericOpenModal from '../../../commons/customHooks/useGenericOpenModal';
import ViewController from './views/viewControllers/ViewController';
import useSnackbarForTotalCashFlow from '../Core/customHooks/useSnackbar';
import RestCacheButton from '../../../shared/components/ResetCacheButton/ResetCacheButton';
import useRowsForExcelDownload from '../Core/customHooks/useRowsForExcelDownload';
import useDownloadTotalCashFlow from '../Core/customHooks/useDownloadTotalCashFlow';

const TotalCashFlowMainView = ({
  onChangeContracts,
  onChangeSalesCashflow,
  salesRows,
  initialDate,
  lastDate,
  constructionStartDate,
  endOfSalesDate,
  financing,
  investorFlow,
  cxp,
  trust,
  financialPerformance,
  spreadIPC,
  clientWithoutQuotation,
  speedSalesIsMissing,
}) => {
  const {
    isLoading,
    updateTotalCashFlow,
    reloadFinancialRows,
  } = useTotalCashFlowToFetchRows(onChangeContracts, onChangeSalesCashflow);
  const buildDateDifference = moment(Number(endOfSalesDate))
    .add(1, 'M')
    .startOf('month')
    .diff(moment(Number(constructionStartDate)).startOf('month'), 'months');

  const { isOpen, onClose, onOpen } = useGenericOpenModal();

  const onCloseSetup = () => {
    onClose();
    reloadFinancialRows();
  };

  useSnackbarForTotalCashFlow({ clientWithoutQuotation, speedSalesIsMissing });

  const rows = [
    ...salesRows,
    ...cxp,
    ...financing,
    ...investorFlow,
    ...trust,
    ...financialPerformance,
    ...spreadIPC,
  ];

  const { dates, orderedRows, maxDate } = useRowsForExcelDownload({ rows });
  
  const dataToDownload = {
    dates,
    initialDate,
    lastDate: maxDate,
    orderedRows,
  };

  const { downloadExcel } = useDownloadTotalCashFlow(dataToDownload);

  const handleClick = () => {
    downloadExcel();
  };

  return (
    <Paper>
      {isLoading && <AbsoluteCircularProgress />}
      <Box p={2}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography variant="h4">Flujo de caja total</Typography>
          </Box>
          <Box>
            <RestCacheButton callback={reloadFinancialRows}>
              Recargar tabla
            </RestCacheButton>
          </Box>
          <Button onClick={onOpen}>Credito constuctor</Button>
          <BuilderCreditSetupModalView isOpen={isOpen} onClose={onCloseSetup} />
          <DownloadButton onClick={handleClick}>
            DESCARGAR EN EXCEL
          </DownloadButton>
        </Box>
        <Box className={Style.selectorsContainer}>
          <TypeSelector />
        </Box>

        {buildDateDifference < 12 ? (
          <Box m={2}>
            <Alert severity="Warning">
              <AlertTitle>Cuidado</AlertTitle>
              El numero de meses debe ser minimo de 12 para poder reajustar, por
              favor revisar construcción.
            </Alert>
          </Box>
        ) : null}

        <ViewController onRowChange={updateTotalCashFlow} rows={rows} />
      </Box>
    </Paper>
  );
};

const mapStateToProps = (state) => {
  const {
    salesRows,
    initialDate,
    lastDate,
    constructionStartDate,
    endOfSalesDate,
    financing = [],
    investorFlow = [],
    cxp = [],
    trust = [],
    financialPerformance = [],
    spreadIPC = [],
    ClientWithoutQuotation,
    speedSalesIsMissing,
  } = state.cashFlow.root;
  return {
    salesRows,
    initialDate,
    lastDate,
    constructionStartDate,
    endOfSalesDate,
    financing,
    investorFlow,
    cxp,
    trust,
    financialPerformance,
    spreadIPC,
    clientWithoutQuotation: ClientWithoutQuotation,
    speedSalesIsMissing,
  };
};

const mapDispatchToProps = {
  onChangeContracts: updateContractCashflow,
  onChangeSalesCashflow: updateSalesCashflow,
};

TotalCashFlowMainView.propTypes = {
  salesRows: PropTypes.array,
  initialDate: PropTypes.number,
  lastDate: PropTypes.number,
  financing: PropTypes.array,
  investorFlow: PropTypes.array,
  cxp: PropTypes.array,
  trust: PropTypes.array,
  financialPerformance: PropTypes.array,
  onChangeContracts: PropTypes.func.isRequired,
  onChangeSalesCashflow: PropTypes.func.isRequired,
  speedSalesIsMissing: PropTypes.bool,
  clientWithoutQuotation: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TotalCashFlowMainView);
